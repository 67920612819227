import { IAcceptancePostData, IBeneficialOwnersData, IFetchAcceptanceResponse } from 'APP/actions/actions.interfaces';
import { Transport } from 'APP/actions/transport';
import { GetPollBankIdResponse } from 'APP/interfaces/authentication.interfaces';
import { InsurancePayment } from 'APP/interfaces/private/offers.interfaces';

interface BankIdResponse {
    data: {
        reference: string;
    };
}

export const AcceptanceActions = {
    get(acceptanceId: string): Promise<IFetchAcceptanceResponse> {
        return Transport.get(`acceptances/${acceptanceId}`);
    },

    post(data: IAcceptancePostData): Promise<IAcceptancePostData> {
        return Transport.post('acceptances', { data });
    },

    postBeneficialOwners(data: IBeneficialOwnersData): Promise<unknown> {
        return Transport.post('applications/beneficial-owner', data);
    },

    startAutogiroBankID(ssn: string): Promise<BankIdResponse> {
        return Transport.post('sign/autogiro', { data: { ssn } });
    },

    getBankIDStatus(reference: string): Promise<GetPollBankIdResponse> {
        return Transport.get(`sign/autogiro/${reference}`);
    },
    getInsurancePayment(queryString: string): Promise<{ data: InsurancePayment[]; status: string }> {
        return Transport.get(`insurances/customer-insurances?${queryString}`);
    },
};
