import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { Transport } from 'APP/actions/transport';
import { IRootState } from 'APP/interfaces/general.interfaces';

export const fetchApplicationsAction = createAsyncThunk('applications/get', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as IRootState;
    const isMortgageApplicationExist = state.applicationsActiveTypesSlice.activeTypes.mortgage;

    const response = await Transport.get('applications');
    if (isMortgageApplicationExist) {
        const responseMortgage = await Transport.get('applications/mortgage');
        return response.data.applications.concat(responseMortgage.data.applications);
    } else {
        return response.data.applications;
    }
});

export const fetchSmartOfferIdAction = createAsyncThunk(
    'applications/getSmartOfferId',
    async (
        { applicationId, applicationRevision }: { applicationId: string; applicationRevision: string },
        thunkAPI
    ) => {
        try {
            const response = await Transport.get(
                `applications/private/${applicationId}/revisions/${applicationRevision}/smart-offer`
            );
            return response.data;
        } catch (error: unknown) {
            if (error instanceof Error) {
                // 404 with this specific message is expected when no smart offers are found
                // so we should avoid capturing it as an error
                if (
                    !error.message.includes(
                        `could not fetch smart offers for application ${applicationId} and revision ${applicationRevision}`
                    )
                ) {
                    Sentry.captureException(error.message);
                    return thunkAPI.rejectWithValue({ message: error.message });
                }
            }
            return thunkAPI.rejectWithValue({ message: 'An unknown error occurred' });
        }
    }
);
