import { ApiStatus } from 'APP/constants/ApiStatusConstants';

export const campaignSliceInitialState = {
    hasCampaign: false,
    existingCampaign: {},
    campaignDataStatus: '',
    activeCampaigns: {
        Kreddy: false,
        Ula: false,
        MobileApp: false,
        Lunar: false,
    },
    apiStatus: {
        updateCampaign: ApiStatus.IDLE,
    },
};
