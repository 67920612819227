export const ApplicationConstants = {
    TYPE: {
        PRIVATE: 'private',
        BUSINESS: 'business',
        MORTGAGE: 'mortgage',
    },
    STATUS: {
        ABORTED: 'aborted',
        PREREQUISITES_COMPLETED: 'prerequisites_completed',
        USER_ACCEPTED_OFFER: 'user_accepted_offer',
        LOAN_PAID: 'loan_paid',
        DENIED: 'denied',
        PRODUCTS_RESPONDED: 'products_responded',
        CANCELLED: 'cancelled',
        BILL_OF_DEBT_SENT: 'bill_of_debt_sent',
        CREATED: 'created',
        APPROVED: 'approved',
    },
    META_STATUS: {
        ABORTED: 'aborted',
        ONGOING: 'ongoing',
        INACTIVATED: 'inactivated',
        PAUSED: 'paused',
        EXPIRED: 'expired',
        FINISHED: 'finished',
        FRAUD: 'fraud',
    },
    FEES: {
        SETUP: 'setup',
        DIRECT_DEBIT: 'direct_debit',
        MANAGE_CREDIT: 'manage_credit',
    },
    CATEGORY: {
        PRIVATE: 'privateloan',
        BUSINESS_LOAN: 'businessloan',
        SAFETY: 'safety',
        ACCIDENT: 'accident',
    },
};

export const Events = {
    INITIALIZE: 'INITIALIZE',
    RESOLVE: 'RESOLVE',
    REJECT: 'REJECT',
};

export const States = {
    IDLE: 'idle',
    RESOLVED: 'resolved',
    REJECTED: 'rejected',
    PENDING_APPLICATIONS: 'pendingApplications',
    NO_APPLICATIONS: 'noApplications',
    NOT_APPLICATION_OWNER: 'notApplicationOwner',
};

export const Effects = {
    SET_APPLICATIONS: 'setApplications',
    REDIRECT: 'redirect',
    REDIRECT_TO_MORTGAGE_PAGE: 'redirectToMortgagePage',
    CAPTURE_ERROR: 'captureError',
    TRACK_NO_APPLICATION: 'trackNoApplication',
    TRACK_ACTIVE_APPLICATIONS: 'trackActiveApplications',
};
export const ApplicationStatus = {
    HAS_OFFERS: 'HAS_OFFERS',
    HAS_DEBTS: 'HAS_DEBTS',
    HAS_ACCEPTANCE: 'HAS_ACCEPTANCE',
    NO_OFFERS: 'NO_OFFERS',
    EXPIRED: 'EXPIRED',
    DENIED: 'DENIED',
    POTENTIAL_BUSINESS_CUSTOMER: 'POTENTIAL_BUSINESS_CUSTOMER',
};
