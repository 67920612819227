import { createSlice } from '@reduxjs/toolkit';
import { ApiStatus } from 'APP/constants/ApiStatusConstants';
import { ApplicationConstants } from 'APP/constants/ApplicationConstants';
import { OfferHelpers } from 'APP/helpers/OfferHelpers';

import { fetchApplicationsAction, fetchSmartOfferIdAction } from './applicationsSliceActions';
import { applicationsSliceInitialState } from './applicationsSliceInitialState';

const initialState = applicationsSliceInitialState;

const applicationsSlice = createSlice({
    name: 'applications',
    initialState,
    reducers: {
        updateRelevantApplication: (state, action) => {
            state.relevantApplication = action.payload;
        },
        makeFetchApplicationsIdle: state => {
            state.apiStatus = ApiStatus.IDLE;
        },
        updateActiveSorterPrivate: (state, action) => {
            state.activeSorter.private = action.payload;
        },
        updateActiveSorterBusiness: (state, action) => {
            state.activeSorter.business = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchApplicationsAction.fulfilled, (state, action) => {
                state.apiStatus = ApiStatus.COMPLETED;
                state.allApplications = action.payload;
                const privateApplications = action.payload.filter(
                    app => app.type === ApplicationConstants.TYPE.PRIVATE
                );
                const businessApplications = action.payload.filter(
                    app => app.type === ApplicationConstants.TYPE.BUSINESS
                );
                const mortgageApplications = action.payload.filter(
                    app => app.type === ApplicationConstants.TYPE.MORTGAGE
                );
                const applications = action.payload.filter(
                    application => application.id === state.relevantApplication?.id
                );
                if (applications.length === 1) {
                    state.relevantApplication = applications[0];
                } else {
                    state.relevantApplication = applications.reduce((a, b) => {
                        return a.revision > b.revision ? a : b;
                    }, []);
                }
                state.bankyOffer.isOnlyBankyOffer = privateApplications.every(
                    app =>
                        (app?.offers?.length === 1 && OfferHelpers.checkPartnerAffiliation(app.offers[0]).isBanky) ||
                        (app?.offers?.length === 0 &&
                            app?.withdrawn_offers?.length === 1 &&
                            OfferHelpers.checkPartnerAffiliation(app.withdrawn_offers[0].offer).isBanky)
                );
                state.bankyOffer.hasBankyOffer = privateApplications.some(app =>
                    app?.offers?.some(offer => OfferHelpers.checkPartnerAffiliation(offer).isBanky)
                );

                state.bankyOffer.isBankyOfferInRelevantApplication = privateApplications[0]?.offers?.some(
                    offer => OfferHelpers.checkPartnerAffiliation(offer).isBanky
                );
                state.private = privateApplications;
                state.business = businessApplications;
                state.mortgage = mortgageApplications;
            })
            .addCase(fetchApplicationsAction.pending, state => {
                state.apiStatus = ApiStatus.STARTED;
            })
            .addCase(fetchApplicationsAction.rejected, state => {
                state.apiStatus = ApiStatus.FAILED;
            });
        builder
            .addCase(fetchSmartOfferIdAction.fulfilled, (state, action) => {
                state.smartOffer.id = action.payload;
                state.smartOffer.apiStatus = ApiStatus.COMPLETED;
            })
            .addCase(fetchSmartOfferIdAction.pending, state => {
                state.smartOffer.apiStatus = ApiStatus.STARTED;
            })
            .addCase(fetchSmartOfferIdAction.rejected, state => {
                state.smartOffer.apiStatus = ApiStatus.FAILED;
            });
    },
});

export const {
    makeFetchApplicationsIdle,
    updateRelevantApplication,
    updateActiveSorterPrivate,
    updateActiveSorterBusiness,
} = applicationsSlice.actions;

export default applicationsSlice.reducer;
