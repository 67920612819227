/* Global styles */
import './normalize.css';
import './global.css';
import '@lendoab/aphrodite/dist/styles.css';
import '@lendoab/aphrodite-file-upload/dist/styles.css';
import '@lendoab/aphrodite/dist/aphrodite-theme.css';
import '@lendoab/aphrodite-chat/dist/styles.css';

import { setDefaultConsentCookies } from '@frontend/shared/utils';
import { DragAndDropProvider } from '@lendoab/aphrodite-file-upload';
import { browserTracingIntegration } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Footer } from 'APP/components/Footer/Footer';
import { CallMeModal, CallMeModalProvider } from 'APP/components/Modals/CallMeModal';
import { Navigation } from 'APP/components/Navigation/Navigation';
import store from 'APP/feature/configStore';
import OptimizelyWrapper from 'APP/Optimizely';
import dayjs from 'dayjs';
import svLocale from 'dayjs/locale/sv';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import objectSupport from 'dayjs/plugin/objectSupport';
import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import { AuthProvider } from '../Authentication';
import styles from './app.module.scss';

const DEFAULT_STALE_TIME = 30 * 1000; // 30 sec

const queryClient = new QueryClient({
    defaultOptions: { queries: { staleTime: DEFAULT_STALE_TIME } },
    queryCache: new QueryCache({
        onError: error => {
            Sentry.captureException(error);
        },
    }),
});

if (process.env.NEXT_PUBLIC_API_MOCKING === 'true') {
    require('../../mocks');
}

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    function resolveEnvironment(): string | undefined {
        if (typeof window !== 'undefined') {
            if (window.location.host.includes('stage.lendo.se') || window.location.host.includes('vercel.app')) {
                return 'stage';
            } else if (window.location.host.includes('lendo.se')) {
                return 'production';
            } else {
                return 'development';
            }
        } else {
            return process.env.ENVIRONMENT;
        }
    }

    function resolveVersion(): string {
        if (process.env.NEXT_PUBLIC_VERSION) {
            return process.env.NEXT_PUBLIC_VERSION;
        } else if (process.env.VERCEL_URL) {
            return process.env.VERCEL_URL;
        } else if (typeof window !== 'undefined') {
            // The window is defined, so we are in the browser
            if (window.location.host.includes('vercel.app')) {
                return window.location.href;
            } else {
                return 'local';
            }
        } else {
            return 'local';
        }
    }

    const environment = resolveEnvironment();
    Sentry.init({
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
        release: resolveVersion(),
        environment,
        enabled: environment !== 'development',
        integrations: [browserTracingIntegration(), Sentry.replayIntegration()],
        replaysOnErrorSampleRate: 0.01,
        tracesSampleRate: 0.1,
        ignoreErrors: [
            /Can't find variable: gmo/gi, // https://issuetracker.google.com/issues/396043331
        ],
    });
}

interface ComponentProps {
    inboxFeature: boolean;
    setInboxFeature: (value: boolean) => void;
    [key: string]: unknown;
}

interface IAppProps {
    Component: (props: ComponentProps) => ReactElement;
    pageProps: { [key: string]: unknown };
}
export default function App({ Component, pageProps }: IAppProps): ReactElement {
    const { query, pathname, push, events } = useRouter();
    const [inboxFeature, setInboxFeature] = useState(false);
    const isLoginPage = pathname === '/';
    const mortgageApplicationId = pathname.includes('mortgage/') ? query.id : null;
    const isMortgageMessagesPage = mortgageApplicationId && pathname.includes('/messages');

    dayjs.extend(localizedFormat);
    dayjs.extend(isBetween);
    dayjs.extend(localeData);
    dayjs.extend(objectSupport);
    dayjs.locale(svLocale);

    useEffect(() => {
        setDefaultConsentCookies();
    }, []);

    useEffect(() => {
        function scrollToTop(): void {
            window.scrollTo(0, 0);
        }

        if (query.inbox_feature !== '' && !inboxFeature) {
            setInboxFeature(query.inbox_feature === 'true');
        }

        events.on('routeChangeComplete', scrollToTop);

        return function cleanup() {
            events.off('routeChangeComplete', scrollToTop);
        };
    }, [query.inbox_feature, inboxFeature, events]);

    function onAuthenticated(): void {
        // No need to redirect if we are already on an authenticated page.
        if (['applications', 'offer', 'accept', 'monto-redirect'].some(p => pathname.includes(p))) {
            return;
        }

        push('/applications');
    }

    function onUnAuthenticated(): void {
        queryClient.clear(); // Clear cached data that was fetched
        push('/');
    }

    return (
        <ReduxProvider store={store}>
            <AuthProvider onAuthenticated={onAuthenticated} onUnAuthenticated={onUnAuthenticated}>
                <CallMeModalProvider>
                    <OptimizelyWrapper>
                        <QueryClientProvider client={queryClient}>
                            <div className={isLoginPage ? styles.containerLogin : styles.container}>
                                <Navigation />
                                <DragAndDropProvider>
                                    <div className={styles.content}>
                                        <Component
                                            inboxFeature={inboxFeature}
                                            setInboxFeature={setInboxFeature}
                                            {...pageProps}
                                        />
                                    </div>
                                </DragAndDropProvider>
                                <Footer display={isMortgageMessagesPage ? ['none', 'none', 'block'] : 'block'} />
                            </div>
                            <CallMeModal />
                            <ReactQueryDevtools initialIsOpen={false} />
                        </QueryClientProvider>
                    </OptimizelyWrapper>
                </CallMeModalProvider>
            </AuthProvider>
        </ReduxProvider>
    );
}
