import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWithdrawnSliceInitialState } from 'APP/interfaces/general.interfaces';
import { IWithdrawnOffer } from 'APP/interfaces/private/offers.interfaces';

const initialState: IWithdrawnSliceInitialState = {
    withdrawnOffers: [],
    lastWithdrawnOffer: {
        acceptance_id: '',
        acceptance_withdrawn_at: '',
        offer: {
            id: '',
            application_id: '',
            revision: 0,
            amount: 0,
            total_cost: 0,
            amortize_length: 0,
            amount_to_solve_external: 0,
            amount_to_solve_internal: 0,
            early_redemption_fee: null,
            product_id: '',
            monthly_cost_first: 0,
            monthly_cost_average: 0,
            monthly_cost_last: 0,
            repayment_type: '',
            interest_rate_nominal: 0,
            interest_rate_effective: 0,
            setup_fee: 0,
            administration_fee: 0,
            invoice_fee: 0,
            autogiro_fee: 0,
            complements: [],
            total_cost_over_max_allowed: false,
            product: {
                id: '',
                name: '',
                partner: '',
                internal_name: '',
                logo_name: '',
                partner_information: {
                    id: '',
                    name: '',
                    internal_name: '',
                },
                settings: [],
            },
            expires_at: '',
            status: '',
            upsells: [],
        },
    },
};

const withdrawnSlice = createSlice({
    name: 'withdrawn',
    initialState,
    reducers: {
        updateWithdrawnOffers: (state, action: PayloadAction<IWithdrawnOffer[]>) => {
            state.withdrawnOffers = action.payload;
        },
        updateLastWithdrawnOffer: (state, action: PayloadAction<IWithdrawnOffer>) => {
            state.lastWithdrawnOffer = action.payload;
        },
    },
});

export const { updateWithdrawnOffers, updateLastWithdrawnOffer } = withdrawnSlice.actions;
export default withdrawnSlice.reducer;
